import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { dispatch } from '../store';
import { axiosInstance as axios } from '../../utils/axios';
import { ENDPOINTS } from '../../config/endpoints';

export interface DurationModel {
  label: string;
  value: number;
}

export interface ProductModel {
  product_id: number;
  is_active: number;
  name_en: string;
  name_ar: string;
  product_code: string;
}

export interface WarrantyModel {
  warranty_id: number;
  warranty_code: string;
  duration: number;
  company_name_en: string;
  company_name_ar: string;
  company_url: string;
  description_en?: string;
  description_ar?: string;
  is_active?: number;
  is_deleted?: number;
  created_at?: number;
  updated_at?: number;
  products?: ProductModel[];
}

interface WarrantySliceState {
  loading: boolean;
  list: WarrantyModel[];
  record?: WarrantyModel;
  count: number;
  params: object;
  durations: DurationModel[];
}

export const warrantySlice = createSlice({
  name: 'warranty',
  initialState: {
    loading: false,
    list: [],
    count: 0,
    params: {},
    durations: [],
  } as WarrantySliceState,
  reducers: {
    startLoading: (state) => {
      state.loading = true;
    },
    stopLoading: (state) => {
      state.loading = false;
    },
    getList: (
      state,
      action: PayloadAction<{ count: number; list: WarrantyModel[] }>
    ) => {
      state.count = action.payload.count;
      state.list = action.payload.list;
      state.loading = false;
    },
    getRecord: (state, action: PayloadAction<WarrantyModel>) => {
      state.record = action.payload;
      state.loading = false;
    },
    setParams: (state, action: PayloadAction<object>) => {
      state.params = action.payload;
    },
    deleteRecord: (state, action: PayloadAction<string | number>) => {
      state.record = undefined;
      state.list = state.list.filter(
        (item) => item.warranty_id === action.payload
      );
      state.loading = false;
    },
    activateRecord: (state, action: PayloadAction<string | number>) => {
      if (state.record?.warranty_id === action.payload)
        state.record.is_active = 1;
      state.list = state.list.map((item) =>
        item.warranty_id === action.payload ? { ...item, is_active: 1 } : item
      );
      state.loading = false;
    },
    deactivateRecord: (state, action: PayloadAction<string | number>) => {
      if (state.record?.warranty_id === action.payload)
        state.record.is_active = 0;
      state.list = state.list.map((item) =>
        item.warranty_id === action.payload ? { ...item, is_active: 0 } : item
      );
      state.loading = false;
    },
    getDurations: (state, action: PayloadAction<DurationModel[]>) => {
      state.durations = action.payload;
      state.loading = false;
    },
  },
});

export async function setParams(params: object): Promise<void> {
  dispatch(warrantySlice.actions.setParams(params));
}

export async function getAll(params?: object): Promise<void> {
  try {
    dispatch(warrantySlice.actions.startLoading());
    const response = await axios.get(ENDPOINTS.shop.warranty.data.getAll, {
      params,
    });
    dispatch(warrantySlice.actions.getList(response.data.response));
  } catch (error) {
    dispatch(warrantySlice.actions.stopLoading());
    throw error;
  }
}

export async function getInfo(id: number): Promise<void> {
  try {
    dispatch(warrantySlice.actions.startLoading());
    const response = await axios.get(ENDPOINTS.shop.warranty.data.getInfo, {
      params: { warranty_id: id },
    });
    dispatch(
      warrantySlice.actions.getRecord({
        ...response.data.response,
      })
    );
  } catch (error) {
    dispatch(warrantySlice.actions.stopLoading());
    throw error;
  }
}

export async function importCsv(file: File): Promise<any> {
  try {
    dispatch(warrantySlice.actions.startLoading());

    const formData = new FormData();
    formData.append('warranties', file, file.name);

    const response = await axios.post(
      ENDPOINTS.shop.warranty.data.import,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    return response.data;
  } catch (error) {
    dispatch(warrantySlice.actions.stopLoading());
    throw error;
  }
}

export async function add(data: any): Promise<any> {
  try {
    dispatch(warrantySlice.actions.startLoading());
    const response = await axios.post(ENDPOINTS.shop.warranty.data.add, data);
    dispatch(warrantySlice.actions.stopLoading());
    return response.data;
  } catch (error) {
    dispatch(warrantySlice.actions.stopLoading());
    throw error;
  }
}

export async function update(data: any): Promise<any> {
  try {
    dispatch(warrantySlice.actions.startLoading());
    const response = await axios.post(
      ENDPOINTS.shop.warranty.data.update,
      data
    );
    dispatch(warrantySlice.actions.stopLoading());
    return response.data;
  } catch (error) {
    dispatch(warrantySlice.actions.stopLoading());
    throw error;
  }
}

export async function activate(id: number): Promise<void> {
  try {
    dispatch(warrantySlice.actions.startLoading());
    const response = await axios.post(ENDPOINTS.shop.warranty.data.activate, {
      warranty_id: id,
    });

    if (response.data.is_successful) {
      dispatch(warrantySlice.actions.activateRecord(id));
      await getInfo(id);
    }
  } catch (error) {
    dispatch(warrantySlice.actions.stopLoading());
    throw error;
  }
}

export async function deactivate(id: number): Promise<void> {
  try {
    dispatch(warrantySlice.actions.startLoading());
    const response = await axios.post(ENDPOINTS.shop.warranty.data.deactivate, {
      warranty_id: id,
    });

    if (response.data.is_successful) {
      dispatch(warrantySlice.actions.deactivateRecord(id));
      await getInfo(id);
    }
  } catch (error) {
    dispatch(warrantySlice.actions.stopLoading());
    throw error;
  }
}

export async function deleteRecord(id: number): Promise<void> {
  try {
    dispatch(warrantySlice.actions.startLoading());
    await axios.post(ENDPOINTS.shop.warranty.data.delete, { warranty_id: id });
    dispatch(warrantySlice.actions.deleteRecord(id));
  } catch (error) {
    dispatch(warrantySlice.actions.stopLoading());
    throw error;
  }
}

export async function addProduct(data: any): Promise<void> {
  const response = await axios.post(ENDPOINTS.shop.warranty.product.add, data);

  if (response.data.is_successful) {
    await getInfo(data.warranty_id);
  }
}

export async function deleteProduct(data: any): Promise<void> {
  const response = await axios.post(
    ENDPOINTS.shop.warranty.product.delete,
    data
  );

  if (response.data.is_successful) {
    await getInfo(data.warranty_id);
  }
}

export async function getDurations(): Promise<void> {
  try {
    dispatch(warrantySlice.actions.startLoading());
    const response = await axios.get(ENDPOINTS.shop.warranty.data.durations);
    dispatch(warrantySlice.actions.getDurations(response.data.response));
  } catch (error) {
    dispatch(warrantySlice.actions.stopLoading());
    throw error;
  }
}
