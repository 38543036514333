import { combineReducers } from '@reduxjs/toolkit';
import { authSlice } from './slices/auth';
import { productSlice } from './slices/product';
import { warrantySlice } from './slices/warranty';
import { brandSlice } from './slices/brand';
import { collectionSlice } from './slices/collection';
import { menuSlice } from './slices/menu';
import { bannerSlice } from './slices/banner';
import { layoutSlice } from './slices/layout';
import { orderSlice } from './slices/order';
import { returnSlice } from './slices/return';
import { subscriptionSlice } from './slices/subscription';
import { userSlice } from './slices/user';
import { flagSlice } from './slices/flag';
import { geoSlice } from './slices/geo';
import { giftcardSlice } from './slices/giftcard';
import { streamSlice } from './slices/stream';
import { standingsSlice } from './slices/standings';

const rootReducer = combineReducers({
	auth: authSlice.reducer,
	product: productSlice.reducer,
	warranty: warrantySlice.reducer,
	brand: brandSlice.reducer,
	collection: collectionSlice.reducer,
	menu: menuSlice.reducer,
	banner: bannerSlice.reducer,
	layout: layoutSlice.reducer,
	order: orderSlice.reducer,
	return: returnSlice.reducer,
	subscription: subscriptionSlice.reducer,
	user: userSlice.reducer,
	flag: flagSlice.reducer,
	geo: geoSlice.reducer,
	giftcard: giftcardSlice.reducer,
	stream: streamSlice.reducer,
	standings: standingsSlice.reducer,
});

export default rootReducer;
